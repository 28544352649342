body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Futura Bk BT', sans-serif!important;
  background-color: #F7F8FC!important;
}

.c-body{
  background-color: #F7F8FC!important;
}

.c-sidebar{
  background: #26245E!important;
}

.c-active{
  border-left: 3px solid;
}

.header-nav{
  background: #249509!important;
}

.outer-container {
  z-index: 99999;
  position: fixed;
  display: table;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  /* background-color: white; */
}

.menunggu{
  animation: dots 1s steps(5, end) infinite;
}

.inner-container {
  z-index: 99999;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
}

.centered-content {
  z-index: 99999;
  display: inline-block;
  text-align: left;
  padding: 20px;
  background-color: rgba(255, 255, 255, 0.5);
  /* border-radius: 20px; */
  /* border: 1px solid whitesmoke; */
}
.inner-loading {
  text-align: center;
}

.three-dots{
  position: absolute;
  right: 1.5rem;
  top: 2rem;
  z-index: 124;
}

.img-nav{
  display: flex;
  justify-content: center;
  align-items: center;
}

.txt-unit{
  font-size: 14px;
  color: #FFFFFF;
  font-weight: 400;
}

.txt-sub-unit{
  font-size: 12px;
  font-weight: 400;
  color: rgba(255, 255, 255, 0.7);
}

.c-footer{
  background: #ffffff!important;
}

.breadc{
  font-size: 14px;
  font-weight: 400;
  color: #5A5A66;
  word-spacing: 0.5rem;
}

.breadc-active{
  font-weight: 600;
  font-size: 14px;
  color: #131324;
}

.txt-header{
  font-weight: bold;
  font-size: 24px;
  color: #131324;
}

.logout-style{
  position: absolute;
  right: 2rem;
  top: 3.3rem;
  background: white;
  color: #131324;
  padding: 0.5rem 4rem;
  cursor: pointer;
}

.logout-style:hover{
  background: #26245E;
  color: #ffffff;
  z-index: 123;
}

.pointer{
  cursor: pointer;
}

.bottom-nav{
  top: 43%;
  transform: translateY(-100%);
}

.pad-bottom-nav{
  padding-bottom: 8rem!important;
}

a.page-link{
  color: black;
}

.page-item.active .page-link{
  background-color: #249509!important;
  border-color: #249509!important;
  border-radius: 6px;
}

.justify-end{
  justify-content: end;
}

.baseline{
  align-items: baseline!important;
}

.footer {
  /* padding-top: 5rem; */
  /* padding-bottom: 1rem; */
  position: absolute;
  bottom: 2rem;
  left: 50%;
  transform: translateX(-50%);
}

.button-masuk {

  padding: 12px 16px 13px;
  height: 48px;
  background: #26245E;
  box-shadow: 0px 5px 15px rgba(38, 36, 94, 0.15);;
  border-radius: 8px;
  margin: 40px 0px;
  font-weight: 600;
  color: #FFFFFF;
  border: none;
  width: 100%;
}

.button-masuk:disabled {
  background: #ADB3BC;
  box-shadow: 0px 5px 15px rgba(173, 179, 188, 0.15);
}

.button-polos{
  border: 1px solid #26245E;
  box-sizing: border-box;
  background: transparent;
  filter: drop-shadow(0px 5px 15px rgba(38, 36, 94, 0.15));
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #8685a3;
  padding: 12px 16px;
  width: 100%;
}

.button-polos:hover{

  background: #26245E;
  color: #FFFFFF;
}

.input:focus{
  outline: none !important;
  border: 1px solid #26245E;
}

.center-vertical{
  display: flex;
  align-items: center;
}

.select {
  /* height: 50px; */
}

.input-error {
  outline: none !important;
  border: 1px solid #E25F57!important;
  background: #FDF9F9!important;
}

.label-error {
  font-size: 10px;
  color: red;
  font-style: italic;
  float: left;
  padding-right: 2px;
}

.badge-pending{
  background: #FEECD2;
  color: #F9A220;
  border-radius: 100px;
  text-align: center;
  font-size: 12px;
  padding: 6px;
}

.badge-reject{
  background: #F5D8D6;
  color: #CB3A31;
  border-radius: 100px;
  text-align: center;
  font-size: 12px;
  padding: 6px;
}

.badge-finish{
  background: #D3EACE;
  color: #249509;
  border-radius: 100px;
  text-align: center;
  font-size: 12px;
  padding: 6px;
}

.badge-menunggu{
  background: #EAEAEF;
  color: #26245E;
  border-radius: 100px;
  text-align: center;
  font-size: 12px;
  padding: 6px;
}

td{
  vertical-align: middle!important;
}

.pointer{
  cursor: pointer;
}

.font-12{
  font-size: 12px;
}

.semua-status {
  font-size: 14px;
  width: 60%;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 6px;
  /* margin: 0px 78px; */
  /* padding: 12px 16px; */
  /* height: 47px; */
  width: 100%;
}

.search-form {
  padding: 12px 16px;
  background: #FFFFFF;
  border: 1px solid #DDDDDD;
  box-sizing: border-box;
  border-radius: 8px;
  /* margin: 0px 31px; */
  width: 100%;
}

.search-icon {
  margin-top: -6.5rem;
  margin-left: 12.5rem;
}

.span-search {
  position: absolute;
  margin-top: 4rem;
  right: 2rem;
}

.tab-style{
  font-size: 16px;
  font-weight: 400;
  color: #777777;
}

.tab-active {
  color : #249509;
  font-weight: 400;
  display: inline-block;
  position: relative;
}

.tab-active:after {
  position: absolute;
  content: '';
  border-bottom: 2px solid #249509;
  width: 100%;
  transform: translateX(-50%);
  bottom: -10px;
  left: 50%;
}

.bg-tab{
  background-color: #F5F9F6;
  padding: 0.75rem 0px;
  margin-right: 0px;
  margin-left: 0px;
  border-bottom: 1px solid #DFE0EB;
}

.center-vertical{
  display: flex;
  align-items: center;
}

.center-vertical-tengah{
  display: flex;
  align-items: center;
  justify-content: center;
}

button:disabled{
  background: #ADB3BC;
  color: #FFFFFF;
}

.button-done{
  border: 1px solid #26245E;
  box-sizing: border-box;
  background: transparent;
  filter: drop-shadow(0px 5px 15px rgba(38, 36, 94, 0.15));
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF ;
  padding: 12px 16px;
  width: 100%;
  background-color: #26245E ;
}

.button-danger{
  border: 1px solid#CB3A31;
  box-sizing: border-box;
  background: transparent;
  filter: drop-shadow(0px 5px 15px rgba(38, 36, 94, 0.15));
  border-radius: 8px;
  font-weight: 600;
  font-size: 14px;
  color: #FFFFFF ;
  padding: 12px 16px;
  width: 100%;
  background-color: #CB3A31 ;
}

